<template>
  <div class="w-full landing-container">
    <div class="header flex items-center w-full shadow-lg">
      <img class="logo" src="../../assets/login_logo.png" />
      <div class="flex items-center gap-8">
        <div class="router-item font-sans">HOME</div>
        <div class="router-item font-sans">ABOUT</div>
        <div class="router-item font-sans" @click="goLogin">LOGIN</div>
      </div>
    </div>
    <div style="background: #ec652b">
      <div class="w-full introduction relative">
        <p class="title ml-6">INSPIRE</p>
        <div class="sub-title">
          <p class="ml-6 mt-3">NOT MERE STORAGE</p>
        </div>
        <div class="p-6 intro-content">
          Refff.ai is a perosnal reference tool designed to inspire you, not
          only storing your information.
        </div>
        <div class="ml-6 mt-20 try-btn" @click="goLogin">TRY FOR FREE</div>
        <img class="intro-bg" src="../../assets/landingBg.png" />
      </div>
    </div>
    <div>
      <div class="reminder">
        <img class="reminder-img" src="../../assets/reminder.png" />
        <div class="reminder-content">
          <p style="color: #ec652b" class="reminder-title">
            Stay Inspired with
          </p>
          <p class="reminder-sub-title">PERIODIC REMINDERS</p>
          <p class="content">
            Refff.ai goes beyond mere storage — we're your partner in continuous
            learning and inspiration.
          </p>
          <p class="content">
            Receive periodic email reminders highlighting key takeaways and
            refreshing your knowledge, ensuring lasting impact and ongoing
            inspiration.
          </p>
        </div>
      </div>
    </div>
    <div>
      <div class="ai-power">
        <p class="title">AI-Powered Support</p>
        <div class="flex my-12">
          <div class="flex support-item">
            <img class="support-img" src="../../assets/summary.png" />
            <div>
              <p class="support-title">Summarize</p>
              <p class="support-content">
                Enjoy automated summaries of essential takeaways, tags, and
                titles from your notes and insightful knowledge.
              </p>
            </div>
          </div>
          <div class="flex support-item">
            <img class="support-img" src="../../assets/vector.png" />
            <div>
              <p class="support-title">Vectorized</p>
              <p class="support-content">
                Preserve your notes and insights in a vector database as a
                component of your AI-assisted knowledge repository. Experience
                an interactive, dialogue-based query and search.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex justify-center">
      <div class="try-free-btn" @click="goLogin">TRY REFFF NOW</div>
    </div>
    <div>
      <img
        src="../../assets/example.png"
        style="max-width: 1440px; margin: -120px auto"
      />
      <div class="example-footer"></div>
    </div>
    <div class="footer shadow-lg">© Refff 2024 All Rights Reserved</div>
  </div>
</template>
<script>
export default {
  name: "landingPage",
  components: {},
  mixins: [],
  props: {},
  data() {
    return {};
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    goLogin() {
      this.$router.push({
        path: "/login",
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.landing-container {
  min-height: 100vh;
  min-width: 960px;
  position: relative;
  .header {
    width: 100%;
    height: 72px;
    margin: 0 auto;
    position: fixed;
    top: 0;
    z-index: 100;
    background: white;
    .logo {
      width: 105px;
      height: 32px;
      margin-left: 200px;
      margin-right: 200px;
    }
    .router-item {
      font-weight: 700;
      cursor: pointer;
      &:hover {
        color: #ec652b;
      }
    }
  }
  .introduction {
    background: #ec652b;
    height: 768px;
    padding: 100px;
    position: relative;
    max-width: 1600px;
    margin: 72px auto 0;
    .title {
      color: white;
      font-size: 100px;
      font-weight: 600;
      line-height: 100px;
    }
    .sub-title {
      width: 100%;
      height: 180px;
      background: #f4a101;
      font-size: 52px;
      font-style: italic;
      font-weight: 275;
      line-height: 52px;
      color: white;
      margin-top: -20px;
    }
    .intro-bg {
      width: 630px;
      position: absolute;
      right: 100px;
      bottom: 100px;
    }
    .intro-content {
      width: 280px;
      color: white;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
    }
    .try-btn {
      width: 260px;
      height: 77px;
      border-radius: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ec652b;
      background: white;
      font-style: normal;
      font-size: 24px;
      font-weight: 700;
      line-height: 16px;
      cursor: pointer;
      &:hover {
        transform: scale(1.05);
        transition: all ease-in 0.2s;
      }
    }
  }
  .reminder {
    max-width: 1080px;
    margin: 72px auto;
    display: flex;
    .reminder-img {
      width: 300px;
    }
    .reminder-content {
      margin-left: 48px;
      .reminder-title {
        font-size: 24px;
      }
      .reminder-sub-title {
        font-size: 40px;
        font-style: italic;
        font-weight: 275;
        line-height: 48px;
        color: #2d3342;
      }
      .content {
        color: #2d3342;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        margin: 32px 0;
      }
    }
  }

  .ai-power {
    max-width: 1080px;
    margin: 72px auto;
    background: #5351fb;
    padding: 48px 96px;
    border-radius: 32px;
    color: white;
    .title {
      font-style: normal;
      font-weight: 600;
      font-size: 48px;
      text-align: center;
    }
    .support-item {
      margin-right: 24px;
      flex: 1;
      .support-img {
        width: 80px;
        height: 80px;
        margin-right: 24px;
      }
      .support-title {
        font-size: 25px;
        font-style: normal;
        font-weight: 600;
      }
      .support-content {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
      }
    }
  }

  .try-free-btn {
    width: 300px;
    height: 77px;
    border-radius: 40px;
    background: linear-gradient(94deg, #ec652b 0%, #fe5024 100%);
    color: #fff;
    font-size: 24px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover {
      transform: scale(1.05);
      transition: all ease-in 0.2s;
    }
  }

  .example-footer {
    width: 100vw;
    height: 240px;
    background: #ec652b;
    margin-top: -240px;
  }

  .footer {
    width: 100vw;
    height: 48px;
    background: white;
    position: fixed;
    bottom: 0;
    color: #777d8d;
    text-align: center;
    line-height: 48px;
  }
}
</style>
